

























































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import SelectMenu from "@/components/SelectMenu.vue";
import UserList from "@/components/UserList.vue";
import GameCard from "@/components/GameCard.vue";
import { messaging } from "./../rio-proto.js";
import { getAllCardsFromDeck } from "@/rest/Game";
import GameStatus from "@/components/GameStatus.vue";
import HintBannerBottom from "@/components/HintBannerBottom.vue";

const GameStateModule = namespace("gameStateModule");

@Component({
  components: {
    SelectMenu,
    UserList,
    GameCard,
    GameStatus,
    HintBannerBottom,
  },
})
export default class Join extends Vue {
  @Prop() private msg!: string;

  @GameStateModule.Getter
  private connectionError!: string;
  @GameStateModule.Getter
  private playerStatus!: messaging.PlayerUpdate;
  @GameStateModule.Getter
  private gameTitle!: string;
  @GameStateModule.Getter
  private gameStatus!: messaging.GameStatus;
  @GameStateModule.Getter
  private gameTopic!: string;
  @GameStateModule.Getter
  private gameTopicLink!: string;
  @GameStateModule.Getter
  private cardDeck!: messaging.CardDeckType;
  @GameStateModule.Getter
  private isUnanimousVote!: boolean;
  @GameStateModule.Getter
  private majorityVotedCard!: messaging.CardDeckCards;
  @GameStateModule.Getter
  private highlightedPlayers!: string[];

  private selectedCard = messaging.CardDeckCards.SPECIAL_NOTCHOSEN;
  private formGameTopic = "";
  private playerName =
    this.$route.query.playerName !== null
      ? String(this.$route.query.playerName)
      : "";
  private gameID = this.$route.params.gameID;

  private showMobileMenu = false;

  mounted() {
    // connect via websocket
    this.$websocketConnect(this.gameID, false, this.playerName);
  }

  startSession(): void {
    this.$websocketSend(
      messaging.Container.create({
        hostUpdate: {
          gameStatus: messaging.GameStatus.RUNNING,
          gameTopic: this.formGameTopic,
        },
      })
    );
  }

  onCardSelect(card: messaging.CardDeckCards): void {
    if (this.gameStatus === messaging.GameStatus.RUNNING) {
      this.selectedCard = card;
      this.$websocketSend(
        messaging.Container.create({ playerUpdate: { card: card } })
      );
    }
  }

  onJoinMajority(): void {
    this.selectedCard = this.majorityVotedCard;
    this.$websocketSend(
      messaging.Container.create({
        playerUpdate: { card: messaging.CardDeckCards.SPECIAL_JOINMAJORITY },
      })
    );
  }

  get hasSelectedCard(): boolean {
    return this.selectedCard !== messaging.CardDeckCards.SPECIAL_NOTCHOSEN;
  }

  get cardDeckCards(): messaging.CardDeckCards[] {
    console.log(this.cardDeck);
    return getAllCardsFromDeck(this.cardDeck);
  }

  get isRunningRound(): boolean {
    return this.gameStatus === messaging.GameStatus.RUNNING;
  }

  get isFinishedRound(): boolean {
    return this.gameStatus === messaging.GameStatus.FINISHED;
  }

  get unanimousVotedCard(): messaging.CardDeckCards {
    return Number(Object.values(this.playerStatus)[0]);
  }

  get majorityVoteExists(): boolean {
    return (
      this.majorityVotedCard !== messaging.CardDeckCards.SPECIAL_NOMAJORITY
    );
  }

  @Watch("gameStatus")
  onPropertyChanged(
    newStatus: messaging.GameStatus,
    oldStatus: messaging.GameStatus
  ) {
    if (
      newStatus === messaging.GameStatus.NEW ||
      newStatus === messaging.GameStatus.RUNNING
    ) {
      this.selectedCard = messaging.CardDeckCards.SPECIAL_NOTCHOSEN;
    }
  }

  @Watch("connectionError")
  onConnetionChanged(newStatus: string, oldStatus: string) {
    if (newStatus !== "") {
      this.$router.push("/");
    }
  }
}
